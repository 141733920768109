<script setup>
import {usePage} from "@inertiajs/inertia-vue3";

defineProps({
  type: {
    type: String,
    default: 'button',
  },
	padding: {
		type: String,
		default: "px-4 py-2"
	}
});


const page = usePage();
</script>

<template>
  <button :type="type" :style="'color:'+ page.props.value.textColor" :class="padding" class="inline-flex items-center bg-white border border-gray-300 rounded-full font-semibold text-xs uppercase tracking-widest shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:text-gray-800 active:bg-gray-50 disabled:opacity-25 transition">
    <slot />
  </button>
</template>